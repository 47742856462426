<!-- Base Grid -->

<template>
  <v-data-table
    class="base_Fgrid"
    v-bind="Entorno.grid.bind"
    :headers="Entorno.grid.headers"
    :items="records"
    show-expand
    single-expand
    :expanded.sync="$store.state[padre].rowSelected">    
  
      <!-- Botones top -->
      <template v-slot:top="">
        <slot name="top_btns" :Sitem="row_selected"></slot>
      </template>

      <!-- Filas Grid -->
      <template v-slot:item="{ item, isExpanded, headers }">
        <tr
          :class="is_selected(item)"                
          @click="selectRow(item)"
          @dblclick="event_btra_Row({ accion: 'dblclick', row:item })">

            <td v-if="Entorno.grid.row.expansible">
              <v-btn icon @click="event_btra_Expansible(item, { accion: 0})">
                <v-icon  v-bind="$cfg.btn.btn_expansible_open">{{ isExpanded? $cfg.btn.btn_expansible_close.icon : $cfg.btn.btn_expansible_open.icon }}</v-icon>
              </v-btn>
            </td>
           
            <template v-for="(header, idx) in headers">                     
              <td class="d-block d-sm-table-cell" :key="idx" 
                v-if="Entorno.grid.row.expansible? (header.value!== '' && header.value!=='data-table-expand'? true : false) : true">                              
                <!-- Botones de acciones -->
                <div v-if="header.value=== 'acciones'" :key="idx">                  
                  <slot name="acciones_grid" v-if="!isExpanded" :Sitem="item"></slot>
                </div>

                <!-- Resto de campos -->
                <div v-else :key="idx">                  
                  <div :style="`${header.style}`">
                    <slot name="fields_grid" :Sitem="item" :Sheader="header">
                      {{ item[header.value] }}
                    </slot>
                  </div>
                </div>

              </td>
            </template>
        </tr>
      </template>

      <!-- Panel expansible -->
      <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pt-3 pb-3">
            <!-- Slot ctrls en panel expansible -->
            <slot
              name="expansible_ctrls"
              :Sitem="item"
              :Sheaders="headers">                     
            </slot>
          </td>
      </template>
  </v-data-table>
</template>


<script>

    import { mapState } from 'vuex';

    export default {
      props: {
        padre: { type: String, default: '' },               
        Entorno: { type: Object, default: () => undefined }
      },

      data() {
        return {
          row_selected: null,      
        };
      },

      computed:mapState({
        records: function(state) { return state[this.padre].records }
      }),

      methods: {
        
        // gestor de eventos de la botonera del Finder (top, row, ...)        
        event_btra_Top(row, evt)
        {
          console.log("*** event_btra_Top. Emit F_Grid ", { accion:evt.accion, row: row? row : {} }, " ***");

          // compruebo si hay alguna linea en edición
          /* if (this.is_edit()) return this.$emit('onEvent', { source:'linea_en_edicion' });
          if (!this.Entorno.grid.row.expansible) return this.$emit("onEvent", { source: 'btra_Top',  accion:evt.accion, row: row? row : {} });
          this.acciones_expansible('btra_Top', row? row:{}, evt.accion); */
        },

        event_btra_Row(evt)
        {
          console.log("*** event_btra_Row. Emit F_Grid ", evt, " ***");
          this.$emit('onEvent', evt);
          
          // compruebo si hay alguna linea en edición
          /* if (this.is_edit()) return this.$emit('onEvent', { source:'linea_en_edicion' });
          if (!this.Entorno.grid.row.expansible) return this.$emit("onEvent", { source: 'btra_Row',  accion:evt.accion, row: row? row : {} });          
          this.acciones_expansible('btra_Row', row, evt.accion);           */
        },

        event_btra_Expansible(row, evt) {         
          if (this.is_hijoEdit()) return this.$emit('onEvent', { accion:'linea_en_edicion' });          
          this.acciones_expansible(row, evt.accion);
        },

        
        // actualizo la fila seleccionada y abro/cierro expansible si no esta en edición
        acciones_expansible(row, accion)
        { 
          console.log('acciones_expansible', row, accion, this.$store.state[this.padre].rowSelected.length);
          if (this.$store.state[this.padre].rowSelected.length== 0) {        
            this.$store.commit(this.padre + '/data2State', { prop:'rowSelected', value:[row] });
            this.$emit('onEvent', { accion:accion, row:row });
            return;
          }          
          
          // cierro expansible          
          if (this.$store.state[this.padre].rowSelected[0]== row) {
            this.$store.commit(this.padre + '/data2State', { prop:'rowSelected', value:[] });          
            return;
          }

          // actualizo fila en edición
          this.$store.commit(this.padre + '/data2State', { prop:'rowSelected', value:[row] });
          
          // abro expansible
          this.$emit('onEvent', { accion: accion, row:row });
        },

       
        // si la fila está seleccionada, devuelvo el estilo de la selección
        is_selected() {
          /* if (this.Entorno.grid.row.selectable === false) return "";
      
          if (this.Entorno.grid.row.selected_items.indexOf(row.index) < 0) return "";
          return this.Entorno.grid.row.class;   */       
          return "";
        },

        // selecciono fila recibida
        selectRow(row) {
          console.log("SELECCIONO FILA ", row);
          if (!row.item) return;
          if (row.isSelected) {
            row.select(false);
            return;
          }

          if (this.Entorno.grid.row.selectable === "simple") {
            // deselecciono la fila que teniamos seleccionada anteriormente
            if (this.row_selected) this.row_selected.select(false);

            // seleciono fila recibida y guardo la selección
            row.select(true);
            this.row_selected = row;
            return;
          }

          row.select(true);
        },

        // devuelvo si el hijo está en edición
        is_hijoEdit() {        
          var hijo= this.$store.state[this.padre].hijo;

          if (!hijo) return false;
          return this.$store.state[hijo].estado === "editar" || this.$store.state[hijo].estado=== "nuevo"? true : false;
        }
       
      },

      /* watch: {
        selected_items() {
          console.log("selected_items ha cambiado a ", this.selected_items);
        }
      } */
    };
</script>
